import { makeAutoObservable } from "mobx";
import { parse } from "cookie";
import {
  IServerRobotInUseModel,
  IServerRobotModel,
  IWebsocketMessageModel,
  IWebsocketsOldestUnprocessedRequestModel,
  IWebsocketsTransportsByQueueModel,
  IWebsocketTableMessagesModel,
  WebsocketMessageType,
  IWebsocketTableMessagesActiveRobotModel,
  IWebsocketTableMessagesActiveRobotModelContent,
  IWebsocketBufferModel,
} from "models/server-models";
import {
  IWebsocketFatalTableMessagesClientModelContent,
  IWebsocketTableMessagesClientModelContent
} from "models/client-models";
import SharedContextStore from "wes_shell_app/shared-context-store";
import {
  serverToClientRequestModel,
  serverToClientRobotModel,
  serverToClientRobotUtilizationModel,
  serverToClientTableMessageContent,
  serverToClientBuffersMessageModel,
  serverToClientFatalTableMessageContent
} from "models/mappers";
import { IClientWebsocketRobotUtilizationModel } from "models/client-models";

import { environment } from "environment";

const TOKEN = "wes-token";

interface IAuthTokens {
  accessToken: string;
}

export const getToken = (): IAuthTokens => ({
  accessToken: parse(document.cookie)?.[TOKEN],
});

export class AmrDashboardViewStore {
  private readonly sharedContext: SharedContextStore = new SharedContextStore();

  constructor() {
    makeAutoObservable(this);
  }

  totalTransports = 0;
  transportsToday = "";
  operatingRobots = 0;
  totalRobotsCount = 0;
  lowBattery = 0;
  charging = 0;
  mislocalized = 0;
  error = 0;
  runStop = 0;
  utilization = 0;
  oldestMessage = {} as IWebsocketsOldestUnprocessedRequestModel;
  fatalMessagesCount = 0;

  utilizationDataTable = [];
  totalTransportTable: IWebsocketsTransportsByQueueModel[] = [];
  processMessagesDataTable: IWebsocketTableMessagesClientModelContent[] = [];
  technologyMessagesDataTable: IWebsocketTableMessagesClientModelContent[] = [];
  fatalMessagesDataTable: IWebsocketFatalTableMessagesClientModelContent[] = [];

  totalRobotsDataTable: IServerRobotInUseModel[] = [];
  activeRobotMessagesDataTable: IWebsocketTableMessagesActiveRobotModelContent[] = [];
  chargingRobotsDataTable: IServerRobotModel[] = [];
  errorRobotsDataTable: IServerRobotModel[] = [];
  lowBatteryRobotsDataTable: IServerRobotModel[] = [];
  mislocalizedRobotsDataTable: IServerRobotModel[] = [];
  runStopRobotsDataTable: IServerRobotModel[] = [];

  fullBuffersCount = 0;
  totalBuffersCount = 0;
  buffersFullnessText = "";
  buffersFullnessPerc = 0;
  buffersDataTable: IWebsocketBufferModel[] = [];

  transportControlStatus: string = "";

  setTotalTransports = (v: number) => (this.totalTransports = v);
  setTransportsToday = (v: string) => (this.transportsToday = v);
  setTotalTransportsTableData = (v: IWebsocketsTransportsByQueueModel[]) =>
    (this.totalTransportTable = v);
  setOldestMessage = (v: IWebsocketsOldestUnprocessedRequestModel) =>
    (this.oldestMessage = v);
  setOperatingRobots = (v: number) => (this.operatingRobots = v);
  setTotalRobotsCount = (v: number) => (this.totalRobotsCount = v);
  setLowBatteryCount = (v: number) => (this.lowBattery = v);
  setChargingCount = (v: number) => (this.charging = v);
  setMislocalizedCount = (v: number) => (this.mislocalized = v);
  setErrorCount = (v: number) => (this.error = v);
  setRunStopCount = (v: number) => (this.runStop = v);
  setFatalMessagesCount = (v: number) => (this.fatalMessagesCount = v);
  setUtilization = (v: number) => (this.utilization = v);
  setUtilizationDataTableData = (v: IClientWebsocketRobotUtilizationModel[]) =>
    (this.utilizationDataTable = v);


  setProcessMessagesTableData = (v: IWebsocketTableMessagesModel) => {
    this.processMessagesDataTable = v.content.map(x => serverToClientTableMessageContent(x, this.getModule(x.appmoduleUuid)?.name));
  }
  setTechnologyMessagesTableData = (v: IWebsocketTableMessagesModel) => {
    this.technologyMessagesDataTable = v.content.map(x => serverToClientTableMessageContent(x, this.getModule(x.appmoduleUuid)?.name));
  }
  setFatalMessagesTableData = (v: IWebsocketTableMessagesModel) => {
    this.fatalMessagesDataTable = v.content.map(x => serverToClientFatalTableMessageContent(x, this.getModule(x.appmoduleUuid)?.name));
  }

  setOperatingRobotsDataTable = (v: IServerRobotInUseModel[]) =>
    (this.totalRobotsDataTable = v);
  setChargingRobotsDataTable = (v: IServerRobotModel[]) =>
    (this.chargingRobotsDataTable = v);
  setErrorRobotsDataTable = (v: IServerRobotModel[]) => (this.errorRobotsDataTable = v);
  setLowBatteryRobotsDataTable = (v: IServerRobotModel[]) =>
    (this.lowBatteryRobotsDataTable = v);
  setMislocalizedRobotsDataTable = (v: IServerRobotModel[]) =>
    (this.mislocalizedRobotsDataTable = v);
  setrunStopRobotsDataTable = (v: IServerRobotModel[]) =>
    (this.runStopRobotsDataTable = v);

  setFullBuffersCount = (v: number) =>
    (this.fullBuffersCount = v);
  setTotalBuffersCount = (v: number) =>
    (this.totalBuffersCount = v);
  setBuffersFullnessText = (v: string) =>
    (this.buffersFullnessText = v);
  setBuffersFullnessPerc = (v: number) =>
    (this.buffersFullnessPerc = v);
  setBuffersDataTable = (v: IWebsocketBufferModel[]) =>
    (this.buffersDataTable = v);

  setActiveRobotMessagesTableData = (v: IWebsocketTableMessagesActiveRobotModel) => {
    this.activeRobotMessagesDataTable = v.robotActiveList.map(x => x);
  }

  setTransportControl = (v: string) => (this.transportControlStatus = v);

  //websockets
  wsConnection: WebSocket;
  timeout;

  get isLoaded() { return this.sharedContext.appContext.isContextLoaded; }
  private getModule = (uuid: string) => this.sharedContext.appContext.getModule(uuid);

  private getClientIpAddress = async () => {
    try {
      const response = await fetch("https://ipapi.co/json");
      const result = await response.json();
      return result.ip;
    } catch (e) { }
  };

  openConnection = async () => {
    const clientIpAddress = await this.getClientIpAddress();
    this.wsConnection = new WebSocket(
      `${environment.amrtAppWss}${this.sharedContext.appContext.currentStationId}`
    );
    const authMessage = {
      message: "login",
      client: clientIpAddress,
      jwt: getToken().accessToken,
    };

    if (this.wsConnection.readyState === 1) {
      this.wsConnection.send(JSON.stringify(authMessage));
    }

    this.wsConnection.onopen = () => {
      this.wsConnection.send(JSON.stringify(authMessage));
    };

    this.wsConnection.onerror = async (event) => {
      console.log("error", event);
    };

    this.wsConnection.onmessage = async (event) => {
      // first check if connection is live
      if (this.wsConnection.readyState === 1) {
        const data: IWebsocketMessageModel = JSON.parse(event.data);
        console.log(data);
        if (data.messageType === WebsocketMessageType.robot) {
          const {
            chargingRobots,
            errorRobots,
            lowBatteryRobots,
            mislocalizedRobots,
            runStopRobots,
            operatingRobots,
            robotUtilization,
            robotUtilizationList,
            chargingRobotsList,
            errorRobotsList,
            mislocalizedRobotsList,
            runStopRobotsList,
            lowBatteryRobotsList,
            totalRobotsList,
          } = serverToClientRobotModel(data.message);
          this.setChargingCount(chargingRobots);
          this.setErrorCount(errorRobots);
          this.setLowBatteryCount(lowBatteryRobots);
          this.setMislocalizedCount(mislocalizedRobots);
          this.setRunStopCount(runStopRobots);
          this.setOperatingRobots(operatingRobots);
          this.setUtilization(parseFloat(robotUtilization.toFixed(1)));
          this.setUtilizationDataTableData(
            robotUtilizationList.map((x) => serverToClientRobotUtilizationModel(x))
          );
          this.setOperatingRobotsDataTable(totalRobotsList);
          this.setChargingRobotsDataTable(chargingRobotsList);
          this.setErrorRobotsDataTable(errorRobotsList);
          this.setLowBatteryRobotsDataTable(lowBatteryRobotsList);
          this.setMislocalizedRobotsDataTable(mislocalizedRobotsList);
          this.setrunStopRobotsDataTable(runStopRobotsList);
        } else if (data.messageType === WebsocketMessageType.request) {
          const {
            clientTransports,
            totalTransports,
            oldestUnprocessedRequest,
            transposrtsByQueues,
          } = serverToClientRequestModel(data.message);
          this.setTotalTransports(totalTransports);
          this.setTransportsToday(clientTransports);
          this.setOldestMessage(oldestUnprocessedRequest);
          this.setTotalTransportsTableData(transposrtsByQueues);
        } else if (data.messageType === WebsocketMessageType.process) {
          this.setProcessMessagesTableData(data.message);
        } else if (data.messageType === WebsocketMessageType.technical) {
          this.setTechnologyMessagesTableData(data.message);
        } else if (data.messageType === WebsocketMessageType.robotActive) {
          this.setActiveRobotMessagesTableData(data.message);
        } else if (data.messageType === WebsocketMessageType.buffer) {
          const {
            fullBuffers,
            totalBuffers,
            buffersFullnessText,
            bufferFullnessPerc,
            bufferList
          } = serverToClientBuffersMessageModel(data.message);
          this.setFullBuffersCount(fullBuffers);
          this.setTotalBuffersCount(totalBuffers);
          this.setBuffersFullnessText(buffersFullnessText);
          this.setBuffersFullnessPerc(bufferFullnessPerc);
          this.setBuffersDataTable(bufferList);
        } else if (data.messageType === WebsocketMessageType.fatal) {
          this.setFatalMessagesCount(data.messageCount.count);
          this.setFatalMessagesTableData(data.message);
        } else if (data.messageType === WebsocketMessageType.transportControl) {
          this.setTransportControl(data.message.status);
        }
      }
    };
  };

  closeConnection = () => {
    if (this.wsConnection) {
      this.wsConnection.close();
    }
  };
}
