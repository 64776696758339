import React from "react";
import { Routes, Route } from "react-router-dom";
import routerStore from "wes_shell_app/router-store";
import { DashboardView } from "../features/dashboard/views/dashboard-view";
import { TransportView } from "../features/transport/views/transport-view";

export enum AmrRoutePaths {
  home = "",
  transport = "/transport",
}

export const AmrRoutes = () => {
  React.useEffect(() => {
    routerStore.addPaths([...Object.values(AmrRoutePaths)]);
  }, []);

  return (
    <Routes>
      <Route path={AmrRoutePaths.home} element={<DashboardView />} />
      <Route path={AmrRoutePaths.transport} element={<TransportView />} />
    </Routes>
  );
};
