import {
  ICommonDescriptionModel,
  IWithActive,
  IWithId,
  IWithName,
} from "wes_shell_app/api-types";
import { Severity } from "wes_shell_app/types";

export enum ITransportState {
  created = "created",
}

export type IServerTransportsModel = IWithId & {
  stationId: number;
  sourceWorkplaceId: number;
  sourcePositionUuid: string;
  sourceUuids: string[];
  targetWorkplaceId: number;
  targetPositionUuid: string;
  targetUuids: string[];
  priority: number;
  queue: string;
  state: string;
  created: Date;
  lastTaskId: number;
  received: Date;
  parentId: number;
  predecessorId: number;
  content: string[];
};

// TOINSPECT are IServerRobotInUseModel & IServerRobotModel the same
// what comes from WSS?
export type IServerRobotInUseModel = IWithId &
  IWithName & {
    status: any;
    batteryLevel: number;
    lowBatteryMode: boolean;
    description: string;
  };

export type IServerRobotModel = IWithId &
  IWithName & {
    state: any;
    batteryLevel: number;
    lowBatteryMode: boolean;
    description: string;
  };

export type IServerUtilizationModel = IWithId &
  IWithName &
  IWithActive & {
    all: Date;
    utilization: any;
  };

export type IServerRequestTransportModel = IWithId & {
  state: string | ITransportState;
  queue: string;
  sourcePositionUuid: string;
  sourceUuids: string[];
  sourceWorkplaceId: number;
  targetPositionUuid: string;
  targetUuids: string[];
  targetWorkplaceId: number;
  priority: number;
  created: Date;
  received: Date;
  lastTaskId: number;
  parentId: number;
  predecessorId: number;
  content: string[];
};

export type IServerCreateTransportRequestModel = IWithId & {
  stationId: number;
  sourceWorkplaceId: number;
  targetWorkplaceId: number;
  sourcePositionUuid: string;
  targetPositionUuid: string;
  sourceUuids: string[];
  targetUuids: string[];
  queue: any;
  priority: number;
  content: string[];
  skipOccupancyCheck?: boolean;
};

export type IServerQueueModel = IWithName & {
  stationId: number;
};

export type IStructureServerModel = ICommonDescriptionModel & {
  stations: IStationServerModel[];
};

export type IStationServerModel = ICommonDescriptionModel & {
  workplaces: IWorkplaceServerModel[];
};

export type Handover =
  "ground"
  | "rack"
  | "bulk-zone"
  | "flowrack"
  | "conveyor"
  | "manual";

export type Operation = "in" | "out" | "inout";

export type IWorkplaceServerModel =
  IWithId &
  IWithName &
  IWithActive & {
    position: IPositionServerModel[];
    handover: Handover;
  };

export type IPositionServerModel = IWithName & {
  id: string;
  name: string;
  lane: number;
  slot: number;
  operation: Operation;
};

export type IWebsocketsTransportsByQueueModel = {
  id: number;
  queue: string;
  count: number;
};

export type IWebsocketsOldestUnprocessedRequestModel = IWithId &
  Omit<IServerRequestTransportModel, "created" | "received" | "task"> & {
    id: number;
    issuedAt: string;
    queue: string;
    priority: number;
    sourceWorkplaceName: string;
    targetWorkplaceName: string;
    sourcePositionName: string;
    targetPositionName: string;
    lastTaskId: number;
  };

export enum WebsocketMessageType {
  robot = "robot",
  message = "message",
  request = "request",
  process = "process",
  technical = "technical",
  robotActive = "robotActive",
  buffer = "buffer",
  fatal = "fatal",
  transportControl = "transportControl",
}

export type WebsocketMessageCount = {
  count: number;
}

export type IWebsocketMessageModel =
  | IWebsocketRobotUtilizationModelRobotMessage
  | IWebsocketRobotUtilizationModelRequestMessage
  | IWebsocketRobotUtilizationModelProcessMessage
  | IWebsocketRobotUtilizationModelTechnicalMessage
  | IWebsocketRobotUtilizationModelActiveMessage
  | IWebsocketRobotUtilizationModelBufferMessage
  | IWebsocketFatalMessageModel
  | IWebsocketTransportControlMessage;

export type IWebsocketRequestModel = {
  completedTransports: number;
  stationId: number;
  totalTransports: number;
  oldestUnprocessedRequest: IWebsocketsOldestUnprocessedRequestModel;
  transposrtsByQueues: IWebsocketsTransportsByQueueModel[];
};

export type IWebsocketRobotModel = {
  stationId: number;
  operatingRobots: number;
  robotsTotal: number;
  chargingRobots: number;
  errorRobots: number;
  lowBatteryRobots: number;
  mislocalizedRobots: number;
  runStopRobots: number;
  robotUtilization: number;
  totalRobotsList: IServerRobotInUseModel[];
  chargingRobotsList: IServerRobotModel[];
  errorRobotsList: IServerRobotModel[];
  lowBatteryRobotsList: IServerRobotModel[];
  mislocalizedRobotsList: IServerRobotModel[];
  runStopRobotsList: IServerRobotModel[];
  robotUtilizationList: IWebsocketRobotUtilizationModel[];
};

export type IWebsocketRobotUtilizationModelRequestMessage = {
  message: IWebsocketRequestModel;
  messageType: WebsocketMessageType.request;
};

export type IWebsocketRobotUtilizationModelRobotMessage = {
  message: IWebsocketRobotModel;
  messageType: WebsocketMessageType.robot;
};

export type IWebsocketRobotUtilizationModelProcessMessage = {
  message: IWebsocketTableMessagesModel;
  messageType: WebsocketMessageType.process;
};

export type IWebsocketRobotUtilizationModelTechnicalMessage = {
  message: IWebsocketTableMessagesModel;
  messageType: WebsocketMessageType.technical;
};

export type IWebsocketRobotUtilizationModelActiveMessage = {
  message: IWebsocketTableMessagesActiveRobotModel;
  messageType: WebsocketMessageType.robotActive;
};
export type IWebsocketRobotUtilizationModelBufferMessage = {
  message: IWebsocketBufferMessageModel;
  messageType: WebsocketMessageType.buffer;
};

export type IWebsocketFatalMessageModel = {
  message: IWebsocketTableMessagesModel;
  messageCount: WebsocketMessageCount;
  messageType: WebsocketMessageType.fatal;
};

export type IWebsocketTransportControlMessage = {
  message: IWebsocketTransportControlModel;
  messageType: WebsocketMessageType.transportControl;
}

export type IWebsocketRobotUtilizationModel = {
  availableTime: number;
  robotId: number;
  robotName: string;
  robotUtilization: number;
  stationId: number;
  workingTime: number;
};

export type IWebsocketTableMessagesModel = {
  content: IWebsocketTableMessagesServerModelContent[];
};

export type IWebsocketTableMessagesServerModelContent = {
  id: number;
  issued: Date;
  severity: Severity;
  confirmed: boolean;
  message: string;
  appmoduleUuid: string;
};

export type IWebsocketTableMessagesActiveRobotModel = {
  robotActiveList: IWebsocketTableMessagesActiveRobotModelContent[];
}

export type IWebsocketTableMessagesActiveRobotModelContent = {
  robotName: string;
  robotStatus: string;
  requestId: number | string;
  taskId: number | string;
  workplaceFrom: string;
  positionFrom: string;
  workplaceTo: string;
  positionTo: string;
  content: string[];
};
export type IWebsocketBufferMessageModel = {
  totalBuffers: number;
  fullBuffers: number;
  bufferList: IWebsocketBufferModel[];
};

export type IWebsocketBufferModel = {
  id: number;
  name: string;
  occupancy: "empty" | "full" | "reserved";
  queue: string;
  workplaceId: number;
  content: string[];
};

export type IWebsocketTransportControlModel = {
  status: string;
};