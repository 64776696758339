import React from "react";
import { observer } from "mobx-react";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { FormLabel } from "@mui/material";
import { useAmrDashboardStore } from "../hooks/use-amr-dashboard-store";
import { AmrDashboardRequestDataProvider } from "../stores/data/amr-dashboard-request-data-provider";
import LocalizedText from "wes_shell_app/localized-text";
import { updateItem } from "wes_shell_app/api-utils";
import { environment } from "environment";

type ButtonIconProps = {
  icon: React.ReactElement;
  type: string;
  status: string;
  onClick: () => void; 
};

const ButtonIcon = ({ icon, type, status, onClick }: ButtonIconProps) => {
  const isDisabled = !status || status === type;
  const color = isDisabled ? "disabled" : "";
  const cursor = isDisabled ? "cursor-auto" : "cursor-pointer";

  return React.cloneElement(icon, {
    sx: { fontSize: 45 },
    className: cursor,
    color: color,
    onClick: () => {
      if (!isDisabled) {
        onClick();
      }
    },
  });
};

export const TaskControl = observer(() => {
  const DashboardStore = useAmrDashboardStore();
  const provider = new AmrDashboardRequestDataProvider();

  if (!DashboardStore.isLoaded) return null;

  const status: string = DashboardStore.transportControlStatus;

  const sendData = async (currentStationID: number, type: string) => {
    try {
      await updateItem(
        `${environment.amrServiceApi}ui/${currentStationID}/${type}`
      );
      console.log("Allocation of requirements: data sent successfully");
    } catch (e) {
      console.error("Error updating item:", e);
    }
  };


  return (
    <React.Fragment>
       {status && (
        <FormLabel sx={{ fontSize: "0.75em" }}>
          {status === "pause" ? (
            <LocalizedText intlId={"stopped"} />
          ) : (
            <LocalizedText intlId={"running"} />
          )}
        </FormLabel>
      )}
      <div className="inline-flex flex-row flex-wrap justify-center gap-7 w-full min-h-[6.5rem] items-center">
        <ButtonIcon
          icon={<PauseCircleOutlineIcon color="disabled"/>}
          type="pause"
          status={status}
          onClick={() => sendData(provider.defaultModel.stationId, "pause")}
        />
        <ButtonIcon
          icon={<PlayCircleOutlineIcon color="disabled"/>}
          type="resume"
          status={status}
          onClick={() => sendData(provider.defaultModel.stationId, "resume")}
        />
      </div>
    </React.Fragment>
  );
});
